import React from 'react'
import Project from '../Project'

function Projects() {
  return (
    <>
    <Project />
    </>
    
  )
}

export default Projects