import React from 'react'
import '../../App.css'
import Sections from '../Sections'

function About() {
  return (
    <>
    <Sections />
    </>
    
  )
}

export default About