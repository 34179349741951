import React from 'react'
import '../../App.css'
import Document from '../Document'
function Resume() {
  return (
    <>
    <Document />
    </>
  )
}

export default Resume